import {useEffect, useState} from "react";
import {IxStatsApi, Region} from "../services/IxStatsApi";
import {Dropdown} from "./Dropdown";

type RegionSelectorProps = {
  initialSelectedRegion?: string
  onChange?: (newSelectedRegion: string) => void
}

export const RegionSelector = ({initialSelectedRegion, onChange}: RegionSelectorProps) => {
  const [selectedRegion, setSelectedRegion] = useState<string>(initialSelectedRegion ? initialSelectedRegion : allRegions)
  const [regions, setRegions] = useState<Region[]>([])

  useEffect(() => {
    (async () => {
      const ixStatsApi = new IxStatsApi(process.env.REACT_APP_IX_STATS_BACKEND_URL)
      const regions = await ixStatsApi.getRegions()
      setRegions(regions.sort((region1, region2) => region1.name.localeCompare(region2.name)))
    })()
  }, [setRegions])

  const options = [{label: "All regions", value: allRegions}, ...regions.map((region) => ({label: region.name, value: region.name}))]

  return <Dropdown
    options={options}
    value={selectedRegion}
    onChange={({target: {value}}) => {
      const newRegion = value as string
      setSelectedRegion(newRegion)
      if (newRegion === allRegions) {
        onChange?.("")
      } else {
        onChange?.(newRegion)
      }
    }}
  />
}

const allRegions = "All"
