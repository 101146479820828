import { useQuery } from "@tanstack/react-query"
import { TimeInterval } from "../components/SelectTime"
import { useScriptHostContext } from "../context/ScriptHostContext"
import { IxStatsApi, TrafficStats } from "../services/IxStatsApi"

interface UseStatisticsProps {
  includePrivate: boolean;
  timeRange: TimeInterval;
  region?: string;
  organisationID?: string;
}

export const useTrafficStats = ({ region, timeRange, organisationID, includePrivate }: UseStatisticsProps) => {
  const scriptHost = useScriptHostContext()
  const ixStatsApi = new IxStatsApi(scriptHost)

  const {
    data: trafficStats,
    isLoading: isLoadingTrafficStats,
    isPlaceholderData: isPlaceholderTrafficStats,
    error,
  } = useQuery([`get${includePrivate ? "Private" : ""}TrafficStats`, timeRange, region, organisationID],
    async () => {
      const trafficStatsParams = { timeRange, region, organisationID }

      if (includePrivate) {
        return await ixStatsApi.getPrivateTrafficStats(trafficStatsParams)
      }

      return await ixStatsApi.getTrafficStats(trafficStatsParams)
    }, {
      retry: 1,
      placeholderData: () => emptyTrafficStats,
      select: (data) => ({ ...data, bits_in: data.bits_in || [], bits_out: data.bits_out || [] })
    })

  return {
    trafficStats: trafficStats || emptyTrafficStats,
    error,
    isLoadingTrafficStats,
    isPlaceholderTrafficStats,
  }
}

const emptyTrafficStats: TrafficStats = {
  bits_in: [],
  bits_out: [],
  organisation: { id: "", name: "" },
  time_period: "daily",
  region: { name: "all" },
}
