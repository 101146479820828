import { DeltaStats } from "../services/TrendsApi"
import { Trend } from "./Trends"
import { Box, Button } from "@mui/material"

type TrendsExportProps = {
  deltaStats: DeltaStats[]
  loading: boolean
  trend: Trend
}


export const TrendsExport = ({ deltaStats, loading, trend }: TrendsExportProps) => {
  const downloadCSV = () => {
    const csvHeader = "Organisation ID,Organisation Name,Previous Value,Previous Start,Previous End,Current Value,Current Start,Current End,Value Delta,Percent Delta\n";
    const csvBody = deltaStats.map((item) => {
      return [
        item.organisation.id,
        item.organisation.name,
        item.previous_value,
        item.previous_start,
        item.previous_end,
        item.current_value,
        item.current_start,
        item.current_end,
        item.value_delta,
        item.percent_delta,
      ].map(field => `"${String(field).replace(/"/g, '""')}"`).join(","); // Wrap each field in quotes and escape existing quotes
    }).join("\n");

    const csv = csvHeader + csvBody;
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = `${trend}.csv`;
    document.body.appendChild(element);
    element.click();
  }

  return (
    <Box>
      <Button variant="contained" onClick={downloadCSV} disabled={loading}>Export as CSV</Button>
    </Box>
  )
}