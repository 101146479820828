import { useEffect, useMemo, useState } from "react"
import { Autocomplete, Paper, TextField } from "@mui/material"
import { Colors } from "../theme/Colors"
import { useOrganisations } from "../hooks/useOrganisations"
import { Organisation } from "../services/IxStatsApi"

interface SelectOrganisationProps {
  includePrivate: boolean;
  organisationId?: string;
  setOrganisationId: (organisationId: string) => void;
}

export const SelectOrganisation = (props: SelectOrganisationProps) => {
  const { organisations, isPlaceholderOrganisations, error } = useOrganisations(props.includePrivate)
  const loadingOrganisations = [{ id: "loading", name: "Loading.." }]

  const allOrganisations = useMemo(() => {
    return [{ id: "all", name: "All organisations" }, ...organisations]
  }, [organisations])

  const [value, setValue] = useState<Organisation>(allOrganisations[0])

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: Organisation | null,
  ) => {
    if (!newValue) props.setOrganisationId("all")
    else props.setOrganisationId(newValue.id)
  }

  useEffect(() => {
    if (!props.organisationId && !value) {
      setValue(allOrganisations[0])
      return
    }

    const found = allOrganisations.find(
      (organisation: Organisation) => organisation.id === props.organisationId,
    )
    if (found !== undefined && value !== found) {
      setValue(found)
    }
  }, [value, props.organisationId, allOrganisations])

  return (
    <Autocomplete
      id="select-organisation"
      sx={selectStyling}
      fullWidth
      autoHighlight
      disabled={isPlaceholderOrganisations || Boolean(error)}
      options={isPlaceholderOrganisations ? loadingOrganisations : allOrganisations}
      getOptionLabel={(organisation: Organisation) => organisation.name}
      renderInput={(params) => <TextField {...params} />}
      PaperComponent={(props) => <Paper sx={paperStyling} {...props} />}
      onChange={handleChange}
      value={
        isPlaceholderOrganisations
          ? loadingOrganisations[0]
          : allOrganisations.find(
            (organisation: Organisation) => organisation.id === value.id,
          )
      }
    />
  )
}

const selectStyling = {
  ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: Colors.netnodGrey,
  },
  ".MuiOutlinedInput-root": {
    padding: "14px",
  },
  ".MuiOutlinedInput-root .MuiAutocomplete-input": {
    padding: "0px",
  },
  // override text field css leaked from Drupal
  "& input": {
    color: "text.primary",
    border: "none",
    boxSizing: "content-box",
    backgroundColor: "transparent",
  },
}

const paperStyling = {
  "& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'].Mui-focused":
    {
      backgroundColor: Colors.lightYellow,
    },
  "& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true']": {
    backgroundColor: Colors.lightYellow,
  },
  "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
    backgroundColor: Colors.transparentLightBlack,
  },
}
