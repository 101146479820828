import { useParamsContext } from "../context/ParamsContext"
import { Toolbar } from "./Toolbar"
import Space from "./Space"
import { Statistics } from "./Statistics"

export const StatisticsPanel = () => {
  const paramsCtx = useParamsContext()
  return (
    <>
      <Toolbar
        isPrivate={paramsCtx.isPrivate}
        region={paramsCtx.region}
        timeRange={paramsCtx.timeRange}
        organisationId={paramsCtx.organisationId}
        graphStyle={paramsCtx.graphStyle}
        setRegion={paramsCtx.setContextRegion}
        setOrganisationId={paramsCtx.setContextOrganisationId}
        setTimeRange={paramsCtx.setContextTimeRange}
        setGraphStyle={paramsCtx.setContextGraphStyle}
      />
      <Space space={2} />
      <Statistics
        region={paramsCtx.region}
        isPrivate={paramsCtx.isPrivate}
        organisationId={paramsCtx.organisationId}
        timeRange={paramsCtx.timeRange}
        graphStyle={paramsCtx.graphStyle}/>
    </>
  )
}
