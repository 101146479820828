import { Box } from "@mui/material";

export const GraphOverlay = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <Box
    sx={{
      position: "absolute",
      width: "100%",
      height: "100%",
      backdropFilter: "blur(5px)",
      zIndex: 1,
    }}
  >
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  </Box>
);
