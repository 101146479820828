import { Box, Grid } from "@mui/material"
import { SelectOrganisation } from "./SelectOrganisation"
import { SelectRegion } from "./SelectRegion"
import { SelectTime, TimeInterval } from "./SelectTime"
import { GraphStyle, StyleSelector } from "./StyleSelector"

type ToolbarProps = {
  isPrivate: boolean,
  region: string,
  timeRange: TimeInterval,
  organisationId: string,
  graphStyle: GraphStyle,
  setRegion: (region: string) => void,
  setOrganisationId: (organisationId: string) => void,
  setTimeRange: (timeRange: TimeInterval) => void,
  setGraphStyle: (graphStyle: GraphStyle) => void,
}

export const Toolbar = (props: ToolbarProps) => {
  return (
    <Box sx={boxStyling}>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        sx={{ alignItems: "center" }}
      >
        <Grid item xs={12} sm={2} md={2}>
          <SelectTime timeRange={props.timeRange} setTimeRange={props.setTimeRange} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <SelectRegion region={props.region} setRegion={props.setRegion} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <SelectOrganisation
            includePrivate={props.isPrivate}
            organisationId={props.organisationId}
            setOrganisationId={props.setOrganisationId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={5}
          sx={{ display: "flex", justifyContent: { xs: "center", md: "end" } }}
        >
          <StyleSelector
            style={props.graphStyle}
            setStyle={props.setGraphStyle}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const boxStyling = {
  root: {
    flexGrow: 1,
  },
}
