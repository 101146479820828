import { useState, useEffect, useCallback } from "react"

const setLocationParam = (key: string, value?: string) => {
  // parse hash location
  const params = parseLocationParams(window.location.hash)

  // set new value
  if (value === undefined) {
    params.delete(key)
  } else {
    params.set(key, value)
  }

  // create hash string
  let newHash = ""
  params.forEach((v: string, k: string) => {
    newHash += `&${k}=${encodeURIComponent(v)}`
  })

  // set new hash string, remove initial '&'
  window.location.hash = newHash.slice(1)
}

const getLocationParam = (
  paramName: string,
  def?: string
): string | undefined => {
  const params = parseLocationParams(window.location.hash)

  // update value if changed
  if (params.get(paramName) !== undefined) {
    return params.get(paramName)
  }

  return def
}

const parseLocationParams = (location: string) => {
  const hash = location.replace(/^#/, "") || "/"

  const params = new Map<string, string>()
  hash.split("&").forEach((item) => {
    const parts = item.split("=")

    if (parts.length === 2) {
      params.set(parts[0], decodeURIComponent(parts[1]))
    }
  })

  return params
}

export const useHashParam = (
  paramName: string
): [string | undefined, (newValue?: string) => void] => {
  const [value, setValue] = useState<string | undefined>(
    getLocationParam(paramName, undefined)
  )

  const handleChange = useCallback((newValue?: string) => {
    // set location hash, let handler update value
    setLocationParam(paramName, newValue)
  }, [paramName])

  useEffect(() => {
    // this function is called whenever the hash changes
    const handler = () => {
      const params = parseLocationParams(window.location.hash)
      const newValue: string | undefined = params.get(paramName)

      // update value if changed
      if (params.get(paramName) !== value) {
        setValue(newValue)
      }
    }

    // subscribe to hash changes
    window.addEventListener("hashchange", handler)
    return () => window.removeEventListener("hashchange", handler)
  }, [paramName, value])

  return [value, handleChange]
}

export default useHashParam
