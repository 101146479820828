import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Colors } from "../theme/Colors";
import { capitalize } from "./Statistics";

export type GraphStyle = "fill" | "lines";

interface SelectStyleProps {
  style: GraphStyle;
  setStyle: (newValue: GraphStyle) => void;
}

export const StyleSelector = (props: SelectStyleProps) => {
  const handleChange = (_: React.MouseEvent<HTMLElement>, newValue: GraphStyle) => {
    if (newValue !== null) {
      props.setStyle(newValue);
    }
  };

  return (
    <div>
      <ToggleButtonGroup
        value={props.style}
        onChange={handleChange}
        exclusive
        aria-label="select graph style"
        sx={toggleButtonGroupStyling}
      >
        {STYLES.map((selection) => (
          <ToggleButton
            key={selection}
            value={selection}
            sx={{ minWidth: "80px" }}
          >
            {capitalize(selection)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default StyleSelector;

export const STYLES: GraphStyle[] = ["lines", "fill"];

const toggleButtonGroupStyling = {
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    border: `1px solid ${Colors.lighterBlack}`,
  },
  "& .MuiToggleButtonGroup-grouped": {
    color: Colors.lighterBlack,
    fontSize: "1rem",
    borderRadius: "100px",
    lineHeight: "20px",
    padding: "6px 10px",
    textTransform: "none",
    border: `1px solid ${Colors.lighterBlack}`,
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: Colors.secondary,
    },
    "&:not(.Mui-selected)": {
      color: Colors.lighterBlack,
    },
  },
};
