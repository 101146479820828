import { Box, Typography } from "@mui/material";
import { getSuitablePrefix, prefixToValue } from "./Statistics";
import { Colors } from "../theme/Colors";
import { BACKGROUND_COLORS, BORDER_COLORS } from "./MetricsChart";
import Space from "./Space";

export interface IXAggregateValues {
  label: string;
  min: number;
  max: number;
  mean: number;
}

interface AggregateValuesProps {
  aggregatedDatasets: IXAggregateValues[];
  unit: string;
}

export const AggregateValues = (props: AggregateValuesProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 1,
      }}
    >
      {props.aggregatedDatasets.map(({ label, min, max, mean }: IXAggregateValues, index) =>
        <Box
          key={label}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Circle
            borderColor={BORDER_COLORS[index % BORDER_COLORS.length]}
            backgroundColor={BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]}
          />
          <Space space={1} />
          <Typography sx={typographyStyling}>
            {label} min: {formatValue(min)}
            {props.unit}, mean: {formatValue(mean)}
            {props.unit}, max: {formatValue(max)}
            {props.unit}.
          </Typography>
        </Box>)
      }
    </Box>
  );
};

const typographyStyling = {
  fontSize: "12px",
  color: Colors.netnodGrey,
  fontWeight: 500,
  textAlign: "left",
};

const formatValue = (value: number) => {
  return `${prefixToValue(getSuitablePrefix(value), value).toFixed(
    2
  )} ${getSuitablePrefix(value)}`;
};

interface CircleProps {
  backgroundColor: string;
  borderColor: string;
}

const Circle = ({ backgroundColor, borderColor }: CircleProps) => (
  <Box
    sx={{
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      border: "2px solid",
      borderColor,
      backgroundColor,
    }}
  />
);
