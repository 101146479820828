export class TrendsApi {
  apiUrl: string;

  constructor(baseUrl = window.location.origin) {
    this.apiUrl = `${baseUrl}/api/trends`;
  }

  getWeeklyTrend(region?: string) {
    return this.get("weekly", region)
  }

  getMonthlyTrend(region?: string) {
    return this.get("monthly", region)
  }

  getWeeklyPeakTrend(region?: string) {
    return this.get("weekly_peak", region)
  }

  getMonthlyPeakTrend(region?: string) {
    return this.get("monthly_peak", region)
  }

  private async get(path: string, region?: string) {
    let url = `${this.apiUrl}/${path}`
    if (region) {
      url = `${url}?${new URLSearchParams({region})}`
    }
    const resp = await fetch(url)

    return await resp.json() as DeltaStats[]
  }

}

export type DeltaStats = {
  organisation: {id: string, name: string}
  previous_value: number
  previous_start: number
  previous_end: number
  current_value: number
  current_start: number
  current_end: number
  value_delta: number
  percent_delta: number
}
