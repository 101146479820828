import { useQuery } from "@tanstack/react-query"
import { useScriptHostContext } from "../context/ScriptHostContext"
import { IxStatsApi, Organisation } from "../services/IxStatsApi"

export const useOrganisations = (includePrivate: boolean) => {
  const scriptHost = useScriptHostContext()
  const ixStatsApi = new IxStatsApi(scriptHost)

  const {
    data: organisations,
    isPlaceholderData: isPlaceholderOrganisations,
    error,
  } = useQuery([`get${includePrivate ? "Private" : ""}Organisations`], async () => {
    if (includePrivate) {
      return await ixStatsApi.getPrivateOrganisations()
    }
    return await ixStatsApi.getOrganisations()
  },
  { placeholderData: [], select: (organisations: Organisation[]) => sortOrganisations(organisations), retry: 1 })

  return {
    organisations: organisations || emptyOrganisations,
    error,
    isPlaceholderOrganisations,
  }
}

const emptyOrganisations: Organisation[] = []


const sortOrganisations = (organisations: Organisation[]) => {
  const sortedOrganisations = [...organisations]
  return sortedOrganisations.sort((a: Organisation, b: Organisation) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  })
}
