import { useQuery } from "@tanstack/react-query";
import { useScriptHostContext } from "../context/ScriptHostContext";
import { IxStatsApi, Region } from "../services/IxStatsApi";

export const useRegions = () => {
  const scriptHost = useScriptHostContext();
  const ixStatsApi = new IxStatsApi(scriptHost);

  const {
    data: regions,
    isPlaceholderData: isPlaceholderRegions,
  } = useQuery(["getRegions"], async () => await ixStatsApi.getRegions(), {
    placeholderData: [],
    select: (regions) => sortRegions(regions),
    retry: 1,
  });

  return {
    regions: regions || emptyRegions,
    isPlaceholderRegions,
  };
};

const emptyRegions: Region[] = [];

const sortRegions = (regions: Region[]) => {
  const sortedRegions = [...regions];
  return sortedRegions.sort((a: Region, b: Region) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });
};