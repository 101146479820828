import { DeltaStats, TrendsApi } from "../services/TrendsApi"
import { useEffect, useState } from "react"
import { TrendsTable } from "./TrendsTable"
import { TrendsExport } from "./TrendsExport"
import { Box, Grid } from "@mui/material"
import { Dropdown } from "./Dropdown"
import { RegionSelector } from "./RegionSelector"
import { useNavigate } from "react-router"
import { ALL, useParamsContext } from "../context/ParamsContext"
import { TimeInterval } from "./SelectTime"

export type Trend = "weekly" | "monthly" | "weekly_peak" | "monthly_peak"

const options: {value: Trend, label: string}[] = [
  { value: "weekly", label: "Weekly average trend" },
  { value: "monthly", label: "Monthly average trend" },
  { value: "weekly_peak", label: "Weekly peak trend" },
  { value: "monthly_peak", label: "Monthly peak trend" },
]

export const Trends = () => {
  const navigate = useNavigate()
  const { setContextRegion, setContextTimeRange, setContextOrganisationId } = useParamsContext()
  const [trendData, setTrendData] = useState<DeltaStats[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedTrend, setSelectedTrend] = useState<Trend>("weekly")
  const [selectedRegion, setSelectedRegion] = useState<string>()

  const navigateToChart = (organisationId: string) => {
    const hashParams = new URLSearchParams()
    const timeRange = selectedTrend.split("_")[0] as TimeInterval
    const region = (selectedRegion || ALL).toLowerCase()

    hashParams.append("region", region)
    hashParams.append("time", timeRange)
    hashParams.append("organisation", organisationId)

    setContextRegion(region)
    setContextTimeRange(timeRange)
    setContextOrganisationId(organisationId)

    navigate(`/private#${hashParams.toString()}`)
  }

  useEffect(() => {
    // removes the hash params when we do a history back from chart page
    window.history.replaceState({}, document.title, window.origin + "/trends")

    void (async function() {
      const trendsApi = new TrendsApi(process.env.REACT_APP_IX_STATS_BACKEND_URL)
      setTrendData([])
      setLoading(true)
      let newTrendData = []
      switch (selectedTrend) {
      case "weekly":
        newTrendData = await trendsApi.getWeeklyTrend(selectedRegion)
        break
      case "monthly":
        newTrendData = await trendsApi.getMonthlyTrend(selectedRegion)
        break
      case "weekly_peak":
        newTrendData = await trendsApi.getWeeklyPeakTrend(selectedRegion)
        break
      case "monthly_peak":
        newTrendData = await trendsApi.getMonthlyPeakTrend(selectedRegion)
        break
      }
      setTrendData(newTrendData)
      setLoading(false)
    })()
  }, [selectedTrend, selectedRegion, setTrendData, setLoading])

  let description = ""
  switch (selectedTrend) {
  case "weekly":
    description = "The weekly average trend shows the average rate for the sum of traffic in both directions."
    break
  case "monthly":
    description = "The monthly average trend shows the average rate for the sum of traffic in both directions."
    break
  case "weekly_peak":
    description = "The weekly peak trend shows the highest peak in either direction of traffic."
    break
  case "monthly_peak":
    description = "The monthly peak trend shows the highest peak in either direction of traffic."
    break
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ marginBottom: "30px" }}>
        <Grid item xs={12} sm={8}>
          <Dropdown
            options={options}
            value={selectedTrend}
            onChange={({ target: { value } }) => setSelectedTrend(value as Trend)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RegionSelector
            initialSelectedRegion={selectedRegion}
            onChange={(newRegion) => setSelectedRegion(newRegion)} />
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: "30px" }}>{description}</Box>
      <TrendsExport trend={selectedTrend} deltaStats={trendData} loading={loading} />
      <TrendsTable trend={selectedTrend} deltaStats={trendData} loading={loading} onRowClick={navigateToChart} />
    </Box>
  )
}
