import { createContext, JSX, useCallback, useContext, useEffect, useState } from "react"
import { TIME_RANGES, TimeInterval } from "../components/SelectTime"
import { GraphStyle, STYLES } from "../components/StyleSelector"
import { useRegions } from "../hooks/useRegions"
import { useOrganisations } from "../hooks/useOrganisations"
import { Region } from "../services/IxStatsApi"
import { useHashParam } from "../hooks/useHashParam"

export const ALL = "all"
const NOOP = () => undefined

type ParamsContextType = {
  isPrivate: boolean,
  region: string,
  timeRange: TimeInterval,
  organisationId: string,
  graphStyle: GraphStyle,
  setContextRegion: (region: string) => void,
  setContextOrganisationId: (organisationId: string) => void,
  setContextTimeRange: (timeRange: TimeInterval) => void,
  setContextGraphStyle: (graphStyle: GraphStyle) => void,
}

export const ParamsContext = createContext<ParamsContextType>({
  isPrivate: false,
  region: ALL,
  timeRange: TimeInterval.WEEKLY,
  organisationId: ALL,
  graphStyle: "lines",
  setContextRegion: NOOP,
  setContextOrganisationId: NOOP,
  setContextTimeRange: NOOP,
  setContextGraphStyle: NOOP,
})

export const useParamsContext = () => {
  return useContext(ParamsContext)
}

export const ParamsProvider = ({ children }: { children: JSX.Element | JSX.Element[]; }) => {
  const isPrivate = isPrivatePath()
  const [region, setRegion] = useState<string>(ALL)
  const [organisationId, setOrganisationId] = useState<string>(ALL)
  const [timeRange, setTimeRange] = useState<TimeInterval>(TimeInterval.WEEKLY)
  const [graphStyle, setGraphStyle] = useState<GraphStyle>("lines")

  const [hashOrganisationID, setHashOrganisationID] = useHashParam("organisation")
  const [hashRegion, setHashRegion] = useHashParam("region")
  const [hashGraphStyle, setHashGraphStyle] = useHashParam("style")
  const [hashTimeRange, setHashTimeRange] = useHashParam("time")

  const { regions, isPlaceholderRegions } = useRegions()
  const { organisations, isPlaceholderOrganisations } = useOrganisations(isPrivate)

  const isValidRegion = useCallback((selectedRegion: string) =>
    selectedRegion === ALL || regions.some(({ name }: Region) => name.toLowerCase() === selectedRegion),
  [regions])

  const isValidOrganisationId = useCallback((selectedOrganisationId: string) =>
    selectedOrganisationId === ALL || organisations.some(({ id }) => id === selectedOrganisationId),
  [organisations])


  const setContextRegion = useCallback((selectedRegion: string) => {
    if (isPlaceholderRegions) {
      return
    }

    const newRegion = isValidRegion(selectedRegion) ? selectedRegion : ALL
    setRegion(newRegion)
    setHashRegion(newRegion)
  }, [isPlaceholderRegions, isValidRegion, setHashRegion])

  const setContextOrganisationId = useCallback((selectedOrganisationId: string) => {
    if (isPlaceholderOrganisations) {
      return
    }

    const newOrganisationId = isValidOrganisationId(selectedOrganisationId) ? selectedOrganisationId : ALL
    setOrganisationId(newOrganisationId)
    setHashOrganisationID(newOrganisationId)
  }, [isPlaceholderOrganisations, isValidOrganisationId, setHashOrganisationID])

  const setContextTimeRange = useCallback((selectedTimeRange: TimeInterval) => {
    const newTimeRange = isValidTimeRange(selectedTimeRange) ? selectedTimeRange : TimeInterval.WEEKLY
    setTimeRange(newTimeRange)
    setHashTimeRange(newTimeRange)
  }, [setHashTimeRange])

  const setContextGraphStyle = useCallback((selectedGraphStyle: GraphStyle) => {
    const newGraphStyle = isValidGraphStyle(selectedGraphStyle) ? selectedGraphStyle : "lines"
    setGraphStyle(newGraphStyle)
    setHashGraphStyle(newGraphStyle)
  }, [setHashGraphStyle])

  // The following effects makes sure that the hash params and the selector values are always in sync and have valid values.
  useEffect(() => {
    hashTimeRange && setContextTimeRange(hashTimeRange as TimeInterval)
  }, [hashTimeRange, setContextTimeRange])

  useEffect(() => {
    hashRegion && setContextRegion(hashRegion)
  }, [hashRegion, setContextRegion])

  useEffect(() => {
    hashOrganisationID && setContextOrganisationId(hashOrganisationID)
  }, [hashOrganisationID,setContextOrganisationId])

  useEffect(() => {
    hashGraphStyle && setContextGraphStyle(hashGraphStyle as GraphStyle)
  }, [hashGraphStyle, setContextGraphStyle])

  return(<ParamsContext.Provider value={{
    isPrivate,
    region,
    organisationId,
    timeRange,
    graphStyle,
    setContextRegion,
    setContextOrganisationId,
    setContextTimeRange,
    setContextGraphStyle,
  }}>{children}</ParamsContext.Provider>)
}

const isValidTimeRange = (selectedTimeRange: TimeInterval) => TIME_RANGES.some(({ value }) => value === selectedTimeRange)
const isValidGraphStyle = (selectedGraphStyle: GraphStyle) => STYLES.some((graphStyle) => graphStyle === selectedGraphStyle)

const isPrivatePath = () => window.location.pathname === "/private" || window.location.pathname === "/private/"
