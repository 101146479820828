import { SelectChangeEvent } from "@mui/material";
import { Dropdown, Option } from "./Dropdown";
import { useRegions } from "../hooks/useRegions";
import { useMemo } from "react";
import { Region } from "../services/IxStatsApi";

interface SelectRegionProps {
  region: string;
  setRegion: (newValue: string) => void;
}

export const SelectRegion = (props: SelectRegionProps) => {
  const { regions, isPlaceholderRegions } = useRegions();
  const loadingRegions = [{ value: "loading", label: "Loading.." }];

  const allRegions = useMemo<Option[]>(() => {
    return [{ value: "all", label: "All regions" }, ...regions.map(({ name }: Region) => ({
      value: name.toLowerCase(),
      label: name,
    }))];
  }, [regions]);

  // prevent using out of range option for region selector
  const selectedRegion = allRegions.find(({ value }) => value === props.region)?.value || allRegions[0].value

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    props.setRegion(event.target.value as string);
  };

  return (
    <Dropdown
      value={isPlaceholderRegions ? loadingRegions[0].value : selectedRegion}
      disabled={isPlaceholderRegions || !allRegions}
      options={isPlaceholderRegions ? loadingRegions : allRegions}
      onChange={handleChange}
    />
  );
};

export default SelectRegion;
