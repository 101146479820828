export class IxStatsApi {
  apiUrl: string;

  constructor(baseUrl = window.location.origin) {
    this.apiUrl = `${baseUrl}/api`;
  }

  async getRegions() {
    return await this.get("regions") as Region[]
  }

  async getOrganisations() {
    return await this.get("organisations") as Organisation[];
  }

  async getPrivateOrganisations() {
    return await this.get("private/organisations") as Organisation[];
  }

  async getTrafficStats({ timeRange, region, organisationID }: TrafficStatsParams) {
    const urlWithParams = new URLSearchParams();
    urlWithParams.append("time_period", timeRange);
    if (organisationID) urlWithParams.append("organisation_id", organisationID);
    if (region) urlWithParams.append("region", region);

    return await this.get(`traffic_stats?${urlWithParams.toString()}`) as TrafficStats;
  }

  async getPrivateTrafficStats({ timeRange, region, organisationID }: TrafficStatsParams) {
    const urlWithParams = new URLSearchParams();
    urlWithParams.append("time_period", timeRange);
    if (organisationID) urlWithParams.append("organisation_id", organisationID);
    if (region) urlWithParams.append("region", region);

    return await this.get(`private/traffic_stats?${urlWithParams.toString()}`) as TrafficStats;
  }

  private async get(path: string) {
    const resp = await fetch(`${this.apiUrl}/${path}`)

    return await resp.json()
  }

}

export type Region = { name: string }
export type Organisation = { id: string, name: string }
export type Metric = {
  time: number,
  value: number,
}
export type TrafficStats = {
  organisation: Organisation,
  region: Region,
  time_period: string,
  bits_in: Metric[],
  bits_out: Metric[]
}

type TrafficStatsParams = {
  timeRange: string, region?: string, organisationID?: string
}
