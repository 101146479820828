import { Box, CircularProgress, Paper, Typography } from "@mui/material"
import { TimeInterval } from "./SelectTime"
import { AggregateValues, IXAggregateValues } from "./AggregateValues"
import { MetricsChart } from "./MetricsChart"
import { GraphOverlay } from "./GraphOverlay"
import { useTrafficStats } from "../hooks/useTrafficStats"
import { GraphStyle } from "./StyleSelector"

const ALL = "all"

interface Metric {
  time: number;
  value: number;
}

type StatisticsProps = {
  isPrivate: boolean,
  region: string,
  timeRange: TimeInterval,
  organisationId: string,
  graphStyle: GraphStyle,
}

export const Statistics = (props: StatisticsProps) => {
  const { trafficStats, error, isPlaceholderTrafficStats } = useTrafficStats({
    includePrivate: props.isPrivate,
    timeRange: props.timeRange,
    region: props.region !== ALL ? props.region : undefined,
    organisationID: props.organisationId !== ALL ? props.organisationId : undefined,
  })

  const inData = trafficStats.bits_in.map((bit: Metric) => ({
    ...bit,
    time: bit.time * 1000,
  }))
  const outData = trafficStats.bits_out.map((bit: Metric) => ({
    ...bit,
    time: bit.time * 1000,
  }))
  const bits = inData
    .map(({ value }: Metric) => value)
    .concat(outData.map(({ value }: Metric) => value))

  const prefix = getSuitablePrefix(Math.max(...bits))

  const trafficIn = createChartDataset({
    prefix,
    values: inData,
    label: "Incoming traffic",
  })
  const trafficOut = createChartDataset({
    prefix,
    values: outData,
    label: "Outgoing traffic",
  })

  //show only incoming traffic if all organisations and all regions
  const datasets = (props.organisationId === ALL && props.region === ALL) ? [{
    ...trafficIn,
    label: "Traffic",
  }] : [trafficIn, trafficOut]

  const bitsIn = inData.map(({ value }: { value: number }) => value)
  const bitsOut = outData.map(({ value }: { value: number }) => value)
  const aggregateInData: IXAggregateValues = {
    label: "Incoming traffic",
    max: Math.max(...bitsIn),
    min: Math.min(...bitsIn),
    mean:
      bitsIn.reduce((prev: number, curr: number) => prev + curr, 0) /
      bitsIn.length,
  }
  const aggregateOutData: IXAggregateValues = {
    label: "Outgoing traffic",
    max: Math.max(...bitsOut),
    min: Math.min(...bitsOut),
    mean:
      bitsOut.reduce((prev: number, curr: number) => prev + curr, 0) /
      bitsOut.length,
  }

  //show only aggregated values for incoming traffic if all organisations and all regions
  const aggregatedDatasets: IXAggregateValues[] = (props.organisationId === ALL && props.region === ALL) ? [{
    ...aggregateInData,
    label: "Traffic",
  }] : [aggregateInData, aggregateOutData]

  if (error) {
    return (
      <Paper sx={{ padding: 2 }} variant="outlined">
        <Typography>Error loading chart data, please try again.</Typography>
      </Paper>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {isPlaceholderTrafficStats && (
        <GraphOverlay>
          <CircularProgress size={60} color="inherit" />
        </GraphOverlay>
      )}
      <Box sx={{ flex: 1, width: "100%" }}>
        <MetricsChart
          datasets={datasets}
          fillCharts={props.graphStyle === "fill"}
          ticksStepSize={props.timeRange === TimeInterval.WEEKLY ? 12 : undefined}
          title={
            trafficStats.region && trafficStats.time_period
              ? getChartTitle(trafficStats.region.name, trafficStats.time_period, trafficStats?.organisation?.name)
              : ""
          }
          unit={`${prefix}bit/s`}
        />
      </Box>
      <AggregateValues
        aggregatedDatasets={aggregatedDatasets}
        unit="bits/s"
      />
    </Box>
  )
}

const createChartDataset = ({
  prefix,
  values,
  label,
}: {
  prefix: string;
  values: Metric[];
  label: string;
}) => {
  return {
    data: values.map(({ time, value }) => ({
      x: time + new Date(time).getTimezoneOffset() * 60 * 1000, // convert to UTC
      y: prefixToValue(prefix, value),
    })),
    label,
  }
}

export const prefixToValue = (prefix: string, value: number) => {
  switch (prefix) {
  case "T":
    return value / Math.pow(10, 12)
  case "G":
    return value / Math.pow(10, 9)
  case "M":
    return value / Math.pow(10, 6)
  case "K":
    return value / Math.pow(10, 3)
  default:
    return value / Math.pow(10, 1)
  }
}

export const getSuitablePrefix = (value: number) => {
  if (value >= Math.pow(10, 12)) return "T"
  if (value >= Math.pow(10, 9)) return "G"
  if (value >= Math.pow(10, 6)) return "M"
  if (value >= Math.pow(10, 3)) return "K"
  return ""
}

const getChartTitle = (
  region: string,
  timeRange: string,
  organisation?: string,
) =>
  `${organisation || "All organisations"} - ${
    !region ? "All regions" : capitalize(region)
  } - ${capitalize(timeRange)} - Timestamps in UTC`

export const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1)
}
