import {
  LegendItem,
  LegendElement,
  ChartEvent,
  ChartOptions,
  TooltipItem,
  ChartDataset,
  Point,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { useState } from "react";
import { LineChart } from "./LineChart";

interface MetricsChartProps {
  datasets: ChartDataset<"line", Point[]>[];
  fillCharts: boolean;
  ticksStepSize?: number;
  title: string;
  unit: string;
}

export const MetricsChart = (props: MetricsChartProps) => {
  const [invert, setInvert] = useState<boolean>(true);

  const legendClickHandler = (
    _: ChartEvent,
    legendItem: LegendItem,
    legend: LegendElement<"line">,
  ) => {
    const index = legendItem.datasetIndex;
    const chart = legend.chart;
    if (index === undefined) {
      return;
    }
    if (chart.isDatasetVisible(index)) {
      chart.hide(index);
    } else {
      chart.show(index);
    }
    const visibleDatasets = chart.getVisibleDatasetCount();
    if (visibleDatasets === 1) {
      setInvert(false); // no use in inverting the dataset if only one visible
    } else {
      setInvert(true);
    }
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    locale: "sv",
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
        onClick: legendClickHandler,
      },
      title: {
        display: true,
        text: props.title,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        position: "nearest",
        callbacks: {
          label: (tooltipItem: TooltipItem<"line">) => {
            return `${tooltipItem.dataset.label}: ${Math.abs(
              tooltipItem.parsed.y,
            ).toFixed(2)} ${props.unit}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          parser: "timestamp",
          displayFormats: {
            minute: "HH:mm",
            hour: "HH:mm",
          },
          tooltipFormat: `yyyy-MM-dd HH:mm`,
        },
        ticks: props.ticksStepSize
          ? {
            stepSize: 12,
            callback: (timestamp, index) => {
              const date = new Date(timestamp);
              return index % 2 === 0
                ? `${date.getHours().toString()
                  .padStart(2, "0")}:${date
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}`
                : `${MONTHS_LABEL[date.getMonth()]} ${date.getDate()}`;
            },
          }
          : {},
      },
      y: {
        ticks: {
          callback: (tickValue) =>
            `${
              typeof tickValue === "number" ? tickValue.toFixed(2) : tickValue
            } ${props.unit}`,
        },
        min: invert && props.fillCharts && props.datasets.length === 2 ? undefined : 0,
      },
    },
  };

  const chartData = {
    datasets: props.datasets.map(
      (set: ChartDataset<"line", Point[]>, index: number) => ({
        label: set.label,
        data: set.data.map((point) => {
          const invertY = index === 1 && invert && props.fillCharts; // invert only the second dataset if filled
          const y = invertY ? -1 * point.y : point.y;
          return { ...point, y };
        }),
        borderColor: BORDER_COLORS[index % BORDER_COLORS.length],
        backgroundColor: BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
        fill: props.fillCharts,
      }),
    ),
  };

  return <LineChart data={chartData} options={options} />;
};

export const BORDER_COLORS = [
  "rgb(54, 162, 235)", // blue
  "rgb(255, 99, 132)", // red
  "rgb(255, 159, 64)", // orange
  "rgb(255, 205, 86)", // yellow
  "rgb(75, 192, 192)", // green
  "rgb(153, 102, 255)", // purple
  "rgb(163, 165, 168)", // grey
];

// Border colors with 50% transparency
export const BACKGROUND_COLORS = BORDER_COLORS.map((color) =>
  color.replace("rgb(", "rgba(").replace(")", ", 0.5)"),
);

const MONTHS_LABEL = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
