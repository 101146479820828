import type { SelectProps } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Colors } from "../theme/Colors";

interface DropdownProps extends SelectProps {
  options: Option[];
}

export interface Option {
  value: string;
  label: string;
}

export const Dropdown = (props: DropdownProps) => {
  return (
    <FormControl fullWidth>
      <Select sx={selectStyling} {...props}>
        {props.options.map(({ value, label }: Option) => (
          <MenuItem sx={listItemStyling} key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const selectStyling = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: Colors.netnodGrey,
  },
  ".MuiSelect-select": {
    padding: "14px",
  },
  borderColor: Colors.primary,
  "&.MuiInputBase-root:after": {
    borderColor: Colors.secondary,
  },
};

const listItemStyling = {
  "&:hover": {
    backgroundColor: Colors.transparentLightBlack,
  },
  "&.Mui-selected": {
    backgroundColor: Colors.lightYellow,
  },
  "&.Mui-selected:hover": {
    backgroundColor: Colors.lightYellow,
  },
  "&.Mui-selected.Mui-focusVisible": {
    backgroundColor: Colors.lightYellow,
  },
  "&.Mui-focusVisible": {
    backgroundColor: Colors.transparentLightBlack,
  },
};
