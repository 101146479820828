import { SelectChangeEvent } from "@mui/material";
import { Dropdown } from "./Dropdown";

export enum TimeInterval {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

interface SelectTimeProps {
  timeRange: string;
  setTimeRange: (newValue: TimeInterval) => void;
}

export const SelectTime = (props: SelectTimeProps) => {
  // prevent using out of range option for time selector
  const selectedTimeRange = TIME_RANGES.find(({ value }) => value === props.timeRange)?.value || TimeInterval.WEEKLY

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    props.setTimeRange(event.target.value as TimeInterval);
  };

  return (
    <Dropdown
      value={selectedTimeRange}
      options={TIME_RANGES}
      onChange={handleChange}
    />
  );
};

export const TIME_RANGES = [
  { value: TimeInterval.DAILY, label: "Daily" },
  { value: TimeInterval.WEEKLY, label: "Weekly" },
  { value: TimeInterval.MONTHLY, label: "Monthly" },
  { value: TimeInterval.YEARLY, label: "Yearly" },
];
