import { createContext, JSX, useContext } from "react";

const ScriptHostContext = createContext("");

export const useScriptHostContext = () => {
  return useContext(ScriptHostContext);
};

export const ScriptHostProvider = ({ children }: { children: JSX.Element | JSX.Element[]; }) => {
  let scriptHost = window.location.origin;
  let mainScriptElement = document.getElementById("ixstats");
  if (mainScriptElement != null) {
    let scriptSource = mainScriptElement.getAttribute("src");
    if (scriptSource) {
      scriptHost = new URL(scriptSource).origin;
    }
  }

  return(<ScriptHostContext.Provider value={scriptHost}>{children}</ScriptHostContext.Provider>);
};